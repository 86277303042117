import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Склад",
			desktopTitle: "Всё по полочкам",
			subTitle: (
				<>
					<p className={styles.subtitle}>
						Возьми под контроль весь цикл товарооборота: от&nbsp;закупок
						и&nbsp;прихода до&nbsp;списания, актов разбора и&nbsp;переработки.
						Система в&nbsp;режиме реального времени отображает остатки, недостачи
						и&nbsp;излишки. И&nbsp;подскажет, когда нужно пополнить запасы.
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails: (
				<span className={styles.cta}>
					Быстро, удобно и две недели бесплатно
				</span>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/warehouse-presentation_kz.webp"
					alt="программа учета для кафе"
					breakpoints={[470, 720, 825]}
					sizes="(max-width: 500px) 470px,  (max-width: 1319px) 720px, (min-width: 1320px) 825px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "қойма",
			desktopTitle: "Бәрі өз орнында",
			subTitle: (
				<>
					<p className={styles.subtitle}>
						Тауар айналымының бүкіл циклін бақылауға алыңыз: сатып алудан және кірістен бастап есептен шығаруға,
						бөлшектеу және қайта өңдеу актілеріне дейін. Жүйе нақты уақыт режимінде қалдықтарды, жетіспеушіліктерді
						және артылғанды көрсетеді. Және қорларды қашан толтыру керектігін айтып береді.
					</p>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails: (
				<span className={styles.cta}>
					Тез, ыңғайлы және екі апта тегін
				</span>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/warehouse-presentation_kz.webp"
					alt="программа учета для кафе"
					breakpoints={[470, 720, 825]}
					sizes="(max-width: 500px) 470px,  (max-width: 1319px) 720px, (min-width: 1320px) 825px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Склад",
			desktopTitle: "Порядок на полках",
			subTitle: (
				<>
					<p className={styles.subtitle}>
						Возьми под контроль весь цикл товарооборота: от&nbsp;закупок
						и&nbsp;прихода до&nbsp;списания, актов разбора и&nbsp;переработки.
						Система в&nbsp;режиме реального времени отображает остатки, недостачи
						и&nbsp;излишки. И&nbsp;подскажет, когда нужно пополнить запасы.
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails: (
				<span className={styles.cta}>
					Быстро, удобно и две недели бесплатно
				</span>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/warehouse-presentation_by.webp"
					alt="программа учета для кафе"
					breakpoints={[470, 720, 825]}
					sizes="(max-width: 500px) 470px,  (max-width: 1319px) 720px, (min-width: 1320px) 825px"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	return {
		pageName: "Склад",
		desktopTitle: "Всё по полочкам",
		subTitle: (
			<>
				<p className={styles.subtitle}>
					Возьми под контроль весь цикл товарооборота: от&nbsp;закупок
					и&nbsp;прихода до&nbsp;списания, актов разбора и&nbsp;переработки.
					Программа учета в&nbsp;режиме реального времени показывает остатки, недостачи
					и&nbsp;излишки. И&nbsp;подскажет, когда нужно пополнить запасы.
				</p>
			</>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails: (
			<span className={styles.cta}>
			За 10 минут, без дорогого оборудования,
			<wbr /> с удобным интерфейсом. И две
			<wbr /> недели бесплатно.
		</span>
		),
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				src="./assets/warehouse-presentation.webp"
				alt="программа учета для кафе"
				breakpoints={[470, 720, 825]}
				sizes="(max-width: 500px) 470px,  (max-width: 1319px) 720px, (min-width: 1320px) 825px"
				className={styles.image}
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	};
};

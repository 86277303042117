import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../common/links"

export default [
	{
		title: "Соблюдай закон",
		description: (
			<p className={styles.appDescription}>
				В&nbsp;Quick Resto настроена интеграция с&nbsp;государственными
				системами учёта алкоголя (ЕГАИС), молочной продукции (Меркурий)
				и&nbsp;маркировки товаров (Честный знак). Мы&nbsp;сами отправим сервисам
				необходимые данные и&nbsp;отобразим всё в&nbsp;едином интерфейсе
				бэк-офиса. Возьмём на себя внедрение автоматизации складского учёта
				для ресторана под ключ.
			</p>
		),
		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/warehouse-feature-1.png"
				alt="акт разбора"
				width={740}
				height={740}
				objectFit="contain"
				placeholder="blurred"
				quality={100}
				outputPixelDensities={[1]}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "green",
		actionText: "Подробнее",
		actionLink: pagesLinks.integrations.href,
	},
]

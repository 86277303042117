import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg";

import mediaContent from "./mediaContent.module.scss";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Остатки",
					desktopTitle: "Что в запасе?",
					mobileTitle: "Что в запасе?",
					desktopContent: (
						<p className={mediaContent.smallDescription_kz}>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад.
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					tabletContent: (
						<p>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад. <br />
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					mobileContent: (
						<p>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад. <br />
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.wlAppImage}
							src="./assets/stock1_kz.webp"
							alt={"программа для учета продуктов в кафе"}
							// breakpoints={[440, 630]}
							width={1261}
							height={900}
							// outputPixelDensities={[1, 2]}
							// sizes="(max-width: 500px) 440px,  (min-width: 501px) 630px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Уведомления",
					desktopTitle: "Без просадок \nи стоп-листов",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь&nbsp;запасы и не&nbsp;потеряешь продажи.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь&nbsp;запасы и не&nbsp;потеряешь продажи.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь запасы и не потеряешь продажи.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.shopImage}
							src="./assets/stock2.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Инвентаризация",
					desktopTitle: "Всё по полочкам",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов,
							которое должно остаться на&nbsp;складе. <br />
							Ты&nbsp;увидишь все недостачи и&nbsp;излишки.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов, которое должно остаться на&nbsp;складе. Ты&nbsp;увидишь <br />
							все недостачи и&nbsp;излишки.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов, которое должно остаться на&nbsp;складе. Ты&nbsp;увидишь <br />
							все недостачи и&nbsp;излишки.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.walletImage}
								src="./assets/stock3_kz.webp"
								alt={"инвентаризация в кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қалдықтар",
					desktopTitle: "Қорда не бар?",
					desktopContent: (
						<p className={mediaContent.smallDescription_kz}>
							Қашанда бір кіріске алынған немесе қоймаға ауыстырылған барлық өнімдердің қалдықтарын көрсетеміз.
							Ыңғайлы болу үшін жалпы құн бойынша баған бар.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.wlAppImage}
							src="./assets/stock1_kz.webp"
							alt={"программа для учета продуктов в кафе"}
							// breakpoints={[440, 630]}
							width={1261}
							height={900}
							// outputPixelDensities={[1, 2]}
							// sizes="(max-width: 500px) 440px,  (min-width: 501px) 630px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Хабарламалар",
					desktopTitle: "Шөгусіз және\nстоп-листтерсіз",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Кез келген өнім үшін қоймада бар-жоғы бойынша шекті орнатуға болады. Мәні сыни бола
							салысымен - ингредиент таусылып жатқанын хабарлаймыз. Осылайша сіз қорларды уақытында
							толтырасыз және сатуды жоғалтпайсыз.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.shopImage}
							src="./assets/stock2.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Түгендеу",
					desktopTitle: "Бәрі өз орнында",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қоймада қалуы керек өнімдер санын өзіміз есептейміз. Барлық жетіспеушіліктер мен артылғанды көресіз.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.walletImage}
								src="./assets/stock3_kz.webp"
								alt={"инвентаризация в кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Остатки",
					desktopTitle: "Что в запасе?",
					mobileTitle: "Что в запасе?",
					desktopContent: (
						<p className={mediaContent.smallDescription_kz}>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад.
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					tabletContent: (
						<p>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад. <br />
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					mobileContent: (
						<p>
							Отобразим остатки всех продуктов, когда-либо оприходованных или перемещённых на&nbsp;склад. <br />
							Для удобства есть графа по&nbsp;общей стоимости.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.wlAppImage}
							src="./assets/stock1_by.webp"
							alt={"программа для учета продуктов в кафе"}
							// breakpoints={[440, 630]}
							width={1261}
							height={900}
							outputPixelDensities={[1, 2]}
							// sizes="(max-width: 500px) 440px,  (min-width: 501px) 630px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Уведомления",
					desktopTitle: "Продажи \nбез остановки",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь&nbsp;запасы и не&nbsp;потеряешь продажи.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь&nbsp;запасы и не&nbsp;потеряешь продажи.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты вовремя
							пополнишь запасы и не потеряешь продажи.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.shopImage}
							src="./assets/stock2.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Инвентаризация",
					desktopTitle: "Всё по полочкам",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов,
							которое должно остаться на&nbsp;складе. <br />
							Ты&nbsp;увидишь все недостачи и&nbsp;излишки.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов, которое должно остаться на&nbsp;складе. Ты&nbsp;увидишь <br />
							все недостачи и&nbsp;излишки.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Мы&nbsp;сами рассчитаем количество продуктов, которое должно остаться на&nbsp;складе. Ты&nbsp;увидишь <br />
							все недостачи и&nbsp;излишки.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.walletImage}
								src="./assets/stock3_by.webp"
								alt={"инвентаризация в кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: (
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Остатки",
				desktopTitle: "Что в запасе?",
				mobileTitle: "Что в запасе?",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Онлайн система учёта и&nbsp;управления складом ресторана или кафе отобразит остатки всех
						когда-либо оприходованных или перемещённых продуктов. Для удобства
						есть графа по&nbsp;общей стоимости.
						< br />
						< br />
						Не покупай лишнего — проверь, в  какие блюда входит ингредиент. Вычислишь продукты, которые
						нужны всего для одной невыгодной позиции в меню.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.wlAppImage}
						src="./assets/stock1.webp"
						alt={"программа для учета продуктов в кафе"}
						// breakpoints={[440, 630]}
						width={1261}
						height={900}
						outputPixelDensities={[1, 2]}
						// sizes="(max-width: 500px) 440px,  (min-width: 501px) 630px"
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={100}
					/>
				),
				backdropContent: (
					//TODO: Remove after testing
					// <div className={backdropIconsStyles.backdrop} />
					<div className={backdropIconsStyles.backdrop_image} />
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Уведомления",
				desktopTitle: "Без просадок \nи стоп-листов",
				desktopContent: (
					<p className={mediaContent.mediumDescription}>
						Для любого продукта можно установить порог по&nbsp;наличию
						на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
						сообщим, что ингредиент заканчивается. Пополняй запасы вовремя
						и&nbsp;не&nbsp;потеряешь продажи. Люди ошибаются, а программа учёта для кафе и ресторанов снижает
						человеческий фактор в планировании закупок.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.shopImage}
						src="./assets/stock2.png"
						alt={"уведомления об остатках на складе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: (
					// <div className={backdropIconsStyles.backdrop} />
					<div className={backdropIconsStyles.backdrop_image} />
				),
			},
			{
				desktopContentWidth: 530,
				tabName: "Инвентаризация",
				desktopTitle: "Порядок во всём",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Программа рассчитает количество продуктов, которое должно остаться
						на&nbsp;складе. Видно все недостачи и&nbsp;излишки. Избавим
						от&nbsp;ошибок любое предприятие общепита: программа подходит для
						складского учёта в&nbsp;столовой, кафе или ресторане.
						< br />
						< br />
						Назначай ответственных и настраивай сотрудникам разные права доступа, чтобы предотвратить
						воровство. Определи, кто может работать со складскими документами. У каждого будут свои функции:
						перемещать, списывать, принимать продукты.
					</p>
				),
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.walletImage}
							src="./assets/stock3.png"
							alt={"инвентаризация в кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				backdropContent: (
					// <div className={backdropIconsStyles.backdrop} />
					<div className={backdropIconsStyles.backdrop_image} />
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};

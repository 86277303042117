import React from "react"
import { SolidTabsProps } from "../../../components/_V2/SolidTabs"
import styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales): SolidTabsProps => {
	if (locale === 'ru-KZ') {
		return {
			items: [
				{
					tabName: "Контроль закупочных цен",
					desktopTitle: "Закупай по рыночной цене",
					desktopContent:
						"Устанавливаешь порог отклонения — и система покажет, если какие-то цены \nрастут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					tabletContent:
						"Устанавливаешь порог отклонения — и система покажет, если какие-то цены растут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					mobileContent:
						"Устанавливаешь порог отклонения — и система покажет, если какие-то цены растут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/warehouse-image-1_kz.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Все операции в одной системе",
					desktopTitle: "На все случаи в общепите",
					mobileTitle: "На все случаи \nв общепите",
					tabletTitle: "На все случаи в общепите",
					desktopContent:
						"Все складские операции — в одном месте: инвентаризация, \nприходные накладные, перемещения и списания.",
					tabletContent:
						"Все складские операции — в одном месте: инвентаризация, приходные накладные, перемещения и списания.",
					mobileContent:
						"Все складские операции — в одном месте: инвентаризация, приходные накладные, перемещения и списания.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/warehouse-image-2_kz.webp"}
								alt={"автоматизация учета ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Перемещение между складами",
					desktopTitle: "Распределяй товары",
					desktopContent:
						"Можно быстро перемещать любые позиции между складами или цехами. \nУдобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					tabletContent:
						"Можно быстро перемещать любые позиции между складами или цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					mobileContent:
						"Можно быстро перемещать любые позиции между складами или цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-3_kz.webp"}
								alt={"складской учет в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Приёмка в любой фасовке",
					desktopTitle: "Жонглируй ящиками и бутылками",
					mobileTitle: "Жонглируй ящиками \nи бутылками",
					tabletTitle: "Жонглируй ящиками и бутылками",
					desktopContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, \nпросто укажи количество ящиков — система сама посчитает, сколько это килограмм.",
					tabletContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, просто укажи количество ящиков — система сама посчитает, сколько это килограмм.",
					mobileContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, просто \nукажи количество ящиков — система сама посчитает, сколько это килограмм.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-4_kz.webp"}
								alt={"ведение учета в кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			items: [
				{
					tabName: "Сатып алу бағаларын бақылау",
					desktopTitle: "Нарықтық баға бойынша сатып алыңыз",
					desktopContent:
						"Ауытқуы шегін орнатасыз - және жүйе қандай да бір бағалар тым жылдам өскенін көрсетеді. Мүмкін, жеткізушімен келіссөздер жүргізу керек шығар",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/warehouse-image-1_kz.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Барлық операциялар бір жүйеде",
					desktopTitle: "Қоғамдық тамақтандырудағы барлық жағдайлар үшін",
					desktopContent:
						"Барлық қоймалық операциялар - бір орында; түгендеу, кіріс жүкқұжаттары, ауысулар мен есептен шығарулар",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/warehouse-image-2_kz.webp"}
								alt={"автоматизация учета ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Қоймалар арасында ауысу",
					desktopTitle: "Тауарларды үлестіріңіз",
					desktopContent:
						"Қоймалар немесе цехтар арасында кез келген позицияларды тез ауыстыруға болады. Өнімдердің бір бөлігі асүйден өткізу үшін барға берілгенде ыңғайлы.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-3_kz.webp"}
								alt={"складской учет в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Кез келген қаптамада қабылдау",
					desktopTitle: "Жәшіктерді және бөтелкелерді қолдана беріңіз",
					desktopContent:
						"Тауарларды қалауыңызша қосыңыз: егер кофені жеткізу 20 қаптамадан тұратын жәшіктермен келсе, жай ғана жәшіктер санын көрсетіңіз - жүйе бұл қанша килограмм екенін өзі есептейді.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-4_kz.webp"}
								alt={"ведение учета в кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			items: [
				{
					tabName: "Контроль закупочных цен",
					desktopTitle: "Закупай по рыночной цене",
					desktopContent:
						"Устанавливаешь порог отклонения — и система покажет, если какие-то цены \nрастут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					tabletContent: "Устанавливаешь порог отклонения — и система покажет, если какие-то цены растут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					mobileContent: "Устанавливаешь порог отклонения — и система покажет, если какие-то цены растут слишком быстро. Возможно, стоит провести переговоры с поставщиком.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/warehouse-image-1_by.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Все операции в одной системе",
					desktopTitle: "Всегда в фокусе",
					mobileTitle: "Всегда \nв фокусе",
					desktopContent:
						"Все складские операции — в одном месте: инвентаризация, \nприходные накладные, перемещения и списания.",
					tabletContent:
						"Все складские операции — в одном месте: инвентаризация, приходные накладные, перемещения и списания.",
					mobileContent:
						"Все складские операции — в одном месте: инвентаризация, приходные накладные, перемещения и списания.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/warehouse-image-2_by.webp"}
								alt={"автоматизация учета ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Перемещение между складами",
					desktopTitle: "Жонглируй товарами",
					desktopContent:
						"Можно быстро перемещать любые позиции между складами или цехами. \nУдобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					tabletContent:
						"Можно быстро перемещать любые позиции между складами или цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					mobileContent:
						"Можно быстро перемещать любые позиции между складами или цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-3_by.webp"}
								alt={"складской учет в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Приёмка в любой фасовке",
					desktopTitle: "Всё посчитано за тебя",
					desktopContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, \nпросто укажи количество ящиков — система сама посчитает, сколько это килограмм.",
					tabletContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, просто укажи количество ящиков — система сама посчитает, сколько это килограмм.",
					mobileContent:
						"Добавляй товары как угодно: если поставка кофе приехала ящиками по 20 упаковок, просто \nукажи количество ящиков — система сама посчитает, сколько это килограмм.",
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/warehouse-image-4_by.webp"}
								alt={"ведение учета в кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	return {
		items: [
			{
				tabName: "Контроль закупочных цен",
				desktopTitle: "Закупай по рыночной цене",
				desktopContent:(
				<>
					Устанавливаешь порог отклонения&nbsp;&mdash; и&nbsp;система автоматизация учёта в&nbsp;ресторане
					покажет, если цена продукта выросла слишком быстро. Возможно, стоит провести переговоры
					с&nbsp;поставщиком или найти нового.
					< br />
					< br />
					Используй контроль остатков и&nbsp;отслеживание закупочных цен. Не&nbsp;покупай лишнего
					и&nbsp;не&nbsp;переплачивай за&nbsp;ингредиенты.
				</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__first)}
							src={"./assets/warehouse-image-1.webp"}
							alt={"программа учета для ресторана"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Все операции в одной системе",
				desktopTitle: "Держи руку на пульсе",
				desktopContent:(
					<>
						<p>
							С&nbsp;программой автоматизации складского учёта в&nbsp;облаке все операции&nbsp;&mdash;
							в&nbsp;одном месте: инвентаризация, приходные накладные, перемещения и&nbsp;списания.
							< br/>
							< br/>
							Линейные сотрудники заполняют складские документы на&nbsp;терминале&nbsp;&mdash;
							и&nbsp;накладные автоматически попадают в&nbsp;бэк-офис. Ответственному сотруднику остаётся
							только их&nbsp;проверить и&nbsp;провести.
							< br/>
							< br/>
							Управляющий создаёт в&nbsp;бэк-офисе список позиций для инвентаризации, а&nbsp;сотрудники
							на&nbsp;точке пересчитывают&nbsp;их. Данные автоматически загрузятся в&nbsp;систему.
						</p>
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__second)}
							src={"./assets/warehouse-image-2.webp"}
							alt={"автоматизация учета ресторана"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Перемещение между складами",
				desktopTitle: "Телепортируй товары",
				desktopContent:
					"Можно быстро перемещать любые позиции между складами и даже цехами. \nУдобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
				tabletContent:
					"Можно быстро перемещать любые позиции между складами и даже цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
				mobileContent:
					"Можно быстро перемещать любые позиции между складами и даже цехами. Удобно, когда часть продукции с кухни отдаётся на реализацию в бар.",
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__third)}
							src={"./assets/warehouse-image-3.webp"}
							alt={"складской учет в ресторане"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Приёмка в любой фасовке",
				desktopTitle: "Жонглируй ящиками и бутылками",
				mobileTitle: "Жонглируй ящиками \nи бутылками",
				desktopContent:(
					<>
						Добавляй товары как угодно: если поставка кофе приехала ящиками по&nbsp;20&nbsp;упаковок, просто
						укажи количество ящиков&nbsp;&mdash; система сама посчитает, сколько это килограмм. Когда
						в&nbsp;ресторане есть автоматизация учёта, ошибок становится меньше.
						< br />
						< br />
						Цена зависит от&nbsp;веса порции? Подключай весы к&nbsp;кассовому терминалу&nbsp;&mdash; вес
						блюда автоматически переносится с&nbsp;весов на&nbsp;планшет. Сотрудник не&nbsp;ошибётся
						с&nbsp;вводом данных в&nbsp;пиковые часы, и&nbsp;в&nbsp;складском учёте столовой будет полный
						порядок.
					</>
				),
				mobileContent:(
					<>
						Добавляй товары как угодно: если поставка кофе приехала ящиками по&nbsp;20&nbsp;упаковок, просто
						укажи количество ящиков&nbsp;&mdash; система сама посчитает, сколько это килограмм. Когда
						в&nbsp;ресторане есть автоматизация учёта, ошибок становится меньше.
						< br />
						< br />
						Цена зависит от&nbsp;веса порции? Подключай весы к&nbsp;кассовому терминалу&nbsp;&mdash; вес
						блюда автоматически переносится с&nbsp;весов на&nbsp;планшет. Сотрудник не&nbsp;ошибётся
						с&nbsp;вводом данных в&nbsp;пиковые часы, и&nbsp;в&nbsp;складском учёте столовой будет полный
						порядок.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__third)}
							src={"./assets/warehouse-image-4.webp"}
							alt={"ведение учета в кафе"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
		],
	}
}

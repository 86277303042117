import React from "react";
import { Locales } from "../../../localization/types";
import styles from "./styles.module.scss";

export const getAdvantages = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				title: "Ускорение работы",
				description: (
					<>
						Делегируй работу с&nbsp;накладными сотруднику кассы (по&nbsp;шаблону). Бухгалтеру достаточно проверить
						корректность документов и&nbsp;сделать проводку.
					</>
				),
			},
			{
				title: "Прозрачность",
				description: (
					<>
						Отслеживай подозрительные изменения в&nbsp;отчётах (закупки, списания), чтобы выявить недобросовестных
						сотрудников.
					</>
				),
			},
			{
				title: "Работает в браузере",
				description: (
					<>
						Данные по&nbsp;всем проводкам, статистика, отчёты по&nbsp;продажам и&nbsp;списаниям доступны в&nbsp;бэкофисе
						с&nbsp;любого устройства.
					</>
				),
			},
		];
	}

	if (locale === "kz-KZ") {
		return [
			{
				title: "Жұмысты жеделдету",
				description: (
					<>
						Жүкқұжаттармен жұмысты касса қызметкерін тапсыр (шаблон бойынша). Бухгалтерге құжаттар дұрыстығын
						тексеру және өткізбе жасау жеткілікті.
					</>
				),
			},
			{
				title: "Ашықтық",
				description: (
					<>
						Жосықсыз қызметкерлерді анықтау үшін есептердегі күмәнді өзгерістерді (сатып алуды, есептен шығаруды) қадағалаңыз.
					</>
				),
			},
			{
				title: "Браузерде жұмыс істейді",
				description: (
					<>
						Барлық өткізелер бойынша деректер, статистика, сату және есептен шығару бойынша есептер бэкофисте кез келген құрылғдан қолжетімді.
					</>
				),
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				title: "Сотрудник мечты",
				descriptionClass: styles.advantages_by__one,
				description: (
					<>
						Автоматизация&nbsp;&mdash; дешевле
						квалифицированных бухгалтера и&nbsp;товароведа: всё может делать
						один сотрудник на&nbsp;кассе.
					</>
				),
			},
			{
				title: "Всё на ладони",
				description: "Знаешь, сколько продуктов нужно \nдля приготовления каждого блюда \n— легче отследить подозрительные \nизменения в отчётах \nи недобросовестных сотрудников.",
			},
			{
				title: "Необходимое в наличии",
				description: "Спасём самые популярные блюда \nот попадания в стоп-лист: нужные \nингредиенты всегда в наличии, \nвсё готовится без просадок.",
				descriptionClass: styles.advantages_by__two
			},
		];
	}

	return [
		{
			title: "Экономия",
			description: (
				<>
					Автоматизация учёта ресторана — дешевле квалифицированных
					бухгалтера и&nbsp;товароведа: всё может делать один сотрудник
					на&nbsp;кассе.
				</>
			),
		},
		{
			title: "Прозрачность",
			description: (
				<>
					Знаешь, сколько продуктов нужно для приготовления каждого
					блюда&nbsp;&mdash; легче отследить подозрительные изменения
					в&nbsp;отчётах и&nbsp;недобросовестных сотрудников.
				</>
			),
		},
		{
			title: "Рост прибыли",
			description:
				"Спасём самые популярные блюда от попадания в стоп-лист: нужные ингредиенты всегда в наличии — всё готовится без просадок.",
		},
	];
};

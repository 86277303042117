import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";

import mediaContent from "./mediaContent.module.scss";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 520,
					tabName: "Списания",
					desktopTitle: "Не теряй продукты",
					mobileTitle: "Не теряй продукты",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Внеплановые расходы ингредиентов&nbsp;&mdash; брак, <br />
							истёкший срок годности, разлитое молоко или подарки <br />
							партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном бизнесе. <br />
							Чтобы не&nbsp;&laquo;потерять&raquo; продукты и&nbsp;правильно отразить всё <br />
							в&nbsp;системе, понадобятся акты списания.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Внеплановые расходы ингредиентов вроде брака, истёкшего срока годности, разлитого молока или подарков
							компаниям-партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном бизнесе. Чтобы
							не&nbsp;&laquo;потерять&raquo; продукты и&nbsp;правильно отразить всё в&nbsp;системе, понадобятся акты
							списания.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Внеплановые расходы ингредиентов вроде брака,
							истёкшего срока годности, разлитого молока или подарков компаниям-партнёрам&nbsp;&mdash; обычное дело
							в&nbsp;ресторанном бизнесе. Чтобы не&nbsp;&laquo;потерять&raquo; продукты и правильно отразить всё
							в системе&nbsp;, понадобятся акты списания.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-1_kz.webp"
							alt={"списание продуктов в кафе"}
							// breakpoints={[410, 660, 838, 870]}
							// sizes="(max-width: 500px) 410px, (max-width: 800px) 660px,  (max-width: 1319px) 838px, (min-width: 1320px) 870px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 520,
					tabName: "Переработка",
					desktopTitle: "Не допускай пересорта",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Иногда повара готовят не&nbsp;по&nbsp;техкарте: например, романо закончился, и&nbsp;вместо него
							использовали айсберг. <br />
							Выходит, романо в&nbsp;излишке: по&nbsp;системе списался, <br />
							но&nbsp;отдан не&nbsp;был. А&nbsp;айсберг&nbsp;&mdash; наоборот. <br />
							Чтобы в&nbsp;инвентаризации всё сошлось по&nbsp;весу <br />
							и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					tabletContent: (
						<p>
							Иногда повара готовят не&nbsp;по&nbsp;техкарте: например, романо закончился, и&nbsp;вместо него
							использовали айсберг. Выходит, романо в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
							А&nbsp;айсберг&nbsp;&mdash; наоборот. Чтобы в инвентаризации&nbsp;всё сошлось по&nbsp;весу
							и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					mobileContent: (
						<p>
							Иногда повара готовят не по техкарте:&nbsp;например, романо закончился, и&nbsp;вместо него
							использовали айсберг. Выходит, романо в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
							А&nbsp;айсберг&nbsp;&mdash; наоборот. Чтобы в инвентаризации&nbsp;всё сошлось по
							весу&nbsp;и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-2_kz.webp"
							alt={"акт переработки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
				{
					desktopContentWidth: 520,
					tabName: "Разбор",
					desktopTitle: "Следи за разделкой",
					desktopContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно <br />
							получить филе из&nbsp;целой рыбы. Акт разбора поможет <br />
							зафиксировать точный вес и&nbsp;себестоимость каждого <br />
							ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при <br />
							инвентаризации не&nbsp;будет расхождений.
						</p>
					),
					tabletContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно
							получить филе из&nbsp;целой рыбы. Акт разбора поможет зафиксировать точный вес и&nbsp;себестоимость
							каждого ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при инвентаризации не&nbsp;будет
							расхождений.
						</p>
					),
					mobileContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно
							получить филе из&nbsp;целой рыбы. Акт разбора поможет зафиксировать точный вес и&nbsp;себестоимость
							каждого ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при инвентаризации не&nbsp;будет
							расхождений.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/warehouse-accounting-3_kz.webp"
								alt={"акт разбора"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>
						</>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 520,
					tabName: "Есептен шығару",
					desktopTitle: "Өнімдерді жоғалтпаңыз",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Жарамсыздық, өтіп кеткен жарамдылық мерзімі,
							төгілген сүт немесе серіктес компанияларға сыйлықтар сияқты ингредиенттердің жоспардан тыс
							шығыстары - мейрамханалық бизнестегі әдеттегі жағдай. Өнімдерді "жоғалтып алмау"
							және бәрін жүйеде дұрыс көрсету үшін, есептен шығару ак тілері керек болады.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-1_kz.webp"
							alt={"списание продуктов в кафе"}
							// breakpoints={[410, 660, 838, 870]}
							// sizes="(max-width: 500px) 410px, (max-width: 800px) 660px,  (max-width: 1319px) 838px, (min-width: 1320px) 870px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 520,
					tabName: "Қайта өңдеу",
					desktopTitle: "Қайта сұрыптауға\nжол бермеңіз",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Кейде аспаздар техкарта бойынша тамақ дайындамайды: мысалы, романл таусылып қалды, және оның
							орнына айсбергті пайдаланды. Сондықтан романо артық болып шығады:
							жүйе бойынша есптен шығарылды, бірақ берілген жоқ. Ал айсберг -керісінше.
							Түгендеу кезінде барлығы салмағы мен құны бойынша үйлесуі үшін қайта өңдеу актісі қажет.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-2_kz.webp"
							alt={"акт переработки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
				{
					desktopContentWidth: 520,
					tabName: "Талдау",
					desktopTitle: "Өңдеуді қадағалаңыз",
					desktopContent: (
						<p>
							Албырт сүбесін бөлек сатып алуға болады, және
							бүтін балықтан сүбе алуға болады. Талдау актісі екі жағдайда да әр ингредиенттің дәл салмағы
							мен өзіндік құнын бекітуге көмектеседі - демек, түгендеу кезінде алшақтық болмайды.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/warehouse-accounting-3_kz.webp"
								alt={"акт разбора"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>
						</>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 520,
					tabName: "Списания",
					desktopTitle: "Не теряй продукты",
					mobileTitle: "Не теряй продукты",
					desktopContent: (
						<p>
							Внеплановые расходы ингредиентов&nbsp;&mdash; брак, <br />
							истёкший срок годности, разлитое молоко или подарки <br />
							партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном бизнесе. <br />
							Чтобы не&nbsp;&laquo;потерять&raquo; продукты и&nbsp;правильно отразить всё <br />
							в&nbsp;системе, понадобятся акты списания.
						</p>
					),
					tabletContent: (
						<p>
							Внеплановые расходы ингредиентов&nbsp;&mdash; брак,
							истёкший срок годности, разлитое молоко или подарки партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном
							бизнесе. Чтобы не&nbsp;&laquo;потерять&raquo; продукты и&nbsp;правильно отразить всё в&nbsp;системе,
							понадобятся акты списания.
						</p>
					),
					mobileContent: (
						<p>
							Внеплановые расходы ингредиентов&nbsp;&mdash; брак,
							истёкший срок годности, разлитое молоко или подарки партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном
							бизнесе. Чтобы не&nbsp;&laquo;потерять&raquo; продукты и&nbsp;правильно отразить всё в&nbsp;системе,
							понадобятся акты списания.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-1_by.webp"
							alt={"списание продуктов в кафе"}
							// breakpoints={[410, 660, 838, 870]}
							// sizes="(max-width: 500px) 410px, (max-width: 800px) 660px,  (max-width: 1319px) 838px, (min-width: 1320px) 870px"
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: (
						//TODO: Remove after testing
						// <div className={backdropIconsStyles.backdrop} />
						<div className={backdropIconsStyles.backdrop_image} />
					),
				},
				{
					desktopContentWidth: 520,
					tabName: "Переработка",
					desktopTitle: "Не допускай пересорта",
					desktopContent: (
						<p>
							Иногда повара готовят не&nbsp;по&nbsp;техкарте: например, романо
							закончился, и&nbsp;вместо него использовали айсберг. Выходит, романо
							в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
							А&nbsp;айсберг&nbsp;&mdash; наоборот. Чтобы в&nbsp;инвентаризации всё
							сошлось по&nbsp;весу и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					tabletContent: (
						<p>
							Иногда повара готовят не&nbsp;по&nbsp;техкарте: например, романо закончился, и&nbsp;вместо него
							использовали айсберг. Выходит, романо в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
							А&nbsp;айсберг&nbsp;&mdash; наоборот. <br />
							Чтобы в&nbsp;инвентаризации всё сошлось по&nbsp;весу и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					mobileContent: (
						<p>
							Иногда повара готовят не по техкарте:&nbsp;например, романо закончился, и&nbsp;вместо него
							использовали айсберг. Выходит, романо в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
							А&nbsp;айсберг&nbsp;&mdash; наоборот. Чтобы в инвентаризации&nbsp;всё сошлось по весу&nbsp;и&nbsp;стоимости, нужен акт переработки.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-2_by.webp"
							alt={"акт переработки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
				{
					desktopContentWidth: 520,
					tabName: "Разбор",
					desktopTitle: "Следи за разделкой",
					desktopContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно <br />
							получить филе из&nbsp;целой рыбы. Акт разбора поможет <br />
							зафиксировать точный вес и&nbsp;себестоимость каждого <br />
							ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при <br />
							инвентаризации не&nbsp;будет расхождений.
						</p>
					),
					tabletContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно
							получить филе из&nbsp;целой рыбы. Акт разбора поможет зафиксировать точный вес и&nbsp;себестоимость
							каждого ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при инвентаризации не&nbsp;будет
							расхождений.
						</p>
					),
					mobileContent: (
						<p>
							Можно купить филе лосося отдельно, а&nbsp;можно
							получить филе из&nbsp;целой рыбы. Акт разбора поможет зафиксировать точный вес и&nbsp;себестоимость
							каждого ингредиента в&nbsp;обоих случаях&nbsp;&mdash; значит, при инвентаризации не&nbsp;будет
							расхождений.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/warehouse-accounting-3_by.webp"
								alt={"акт разбора"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>
						</>
					),
					backdropContent: <div className={backdropIconsStyles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 520,
				tabName: "Списания",
				desktopTitle: "Не теряй продукты",
				mobileTitle: "Не теряй продукты",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Внеплановые расходы ингредиентов вроде брака, истёкшего срока
						годности, разлитого молока или подарков
						компаниям-партнёрам&nbsp;&mdash; обычное дело в&nbsp;ресторанном
						бизнесе. Используй акты списания, чтобы не потерять продукты и правильно отразить всё в программе
						складского учёта ресторана.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/warehouse-accounting-1.webp"
						alt={"списание продуктов в кафе"}
						// breakpoints={[410, 660, 838, 870]}
						// sizes="(max-width: 500px) 410px, (max-width: 800px) 660px,  (max-width: 1319px) 838px, (min-width: 1320px) 870px"
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={100}
					/>
				),
				backdropContent: (
					//TODO: Remove after testing
					// <div className={backdropIconsStyles.backdrop} />
					<div className={backdropIconsStyles.backdrop_image} />
				),
			},
			{
				desktopContentWidth: 520,
				tabName: "Переработка",
				desktopTitle: "Не допускай пересорта",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Иногда повара готовят не&nbsp;по&nbsp;техкарте: например, романо
						закончился, и&nbsp;вместо него использовали айсберг. Выходит, романо
						в&nbsp;излишке: по&nbsp;системе списался, но&nbsp;отдан не&nbsp;был.
						А&nbsp;айсберг&nbsp;&mdash; наоборот. Чтобы в&nbsp;инвентаризации всё
						сошлось по&nbsp;весу и&nbsp;стоимости, в&nbsp;программе учёта для
						ресторана предусмотрен акт переработки.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/warehouse-accounting-2.webp"
						alt={"акт переработки"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={100}
					/>
				),
				backdropContent: <div className={backdropIconsStyles.backdrop} />,
			},
			{
				desktopContentWidth: 520,
				tabName: "Разбор",
				desktopTitle: "Контролируй разделку",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Можно купить филе курицы отдельно, а&nbsp;можно получить филе
						из&nbsp;целой курицы. В&nbsp;программе учёта продуктов для кафе есть
						акт разбора. Он&nbsp;поможет зафиксировать точный вес
						и&nbsp;себестоимость каждого ингредиента в&nbsp;обоих
						случаях&nbsp;&mdash; значит, при инвентаризации не&nbsp;будет
						расхождений.
					</p>
				),
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.image}
							src="./assets/warehouse-accounting-3.webp"
							alt={"акт разбора"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={100}
						/>
					</>
				),
				backdropContent: <div className={backdropIconsStyles.backdrop} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};

import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/warehouse.module.scss'
import WarehouseProductPresentation from '../pagesSections/warehouse/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import CRMFeatures from '../pagesSections/warehouse/CRMFeatures'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import { getWarehouseMeta } from '../pagesSections/warehouse/meta'

import { getAdvantages } from '../pages-data/_V2/warehouse/advantages'

import StockFeatures from '../pagesSections/warehouse/StockFeatures'
import AccountingFeatures from '../pagesSections/warehouse/AccountingFeatures'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import featuresData from '../pages-data/_V2/warehouse/features'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function WarehousePage() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getWarehouseMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <WarehouseProductPresentation />

      <Advantages
        items={getAdvantages(localizationContext.locale)}
        className={classNames(
				  styles.warehouse__advantages,
				  pageStyles.pageSection,
        )}
      />

      <CRMFeatures className={pageStyles.pageSection} />

      <StockFeatures className={pageStyles.pageSection} />

      <AccountingFeatures className={pageStyles.pageSection} />

      {localizationContext.locale === 'ru-RU' && (
        <CtaForm isFry={false} className={pageStyles.pageSection} />
      )}

      {localizationContext.locale === 'ru-RU' && (
        <SimpleFeatures data={featuresData} className={pageStyles.pageSection} />
      )}

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}

import { Locales } from "../../../localization/types";
import Presentation from "../ProductPresentation/assets/warehouse-presentation.webp";
import PresentationKz from "../ProductPresentation/assets/warehouse-presentation_kz.webp";
import PresentationBy from "../ProductPresentation/assets/warehouse-presentation_by.webp";
import {externalLinks} from "../../../pages-data/_V2/common/links";


export const getWarehouseMeta = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			title: "Автоматизация складского учета для кафе и ресторанов | Quick Resto Казахстан",
			metaTags: [
				{
					name: "description",
					content:
						"Программа складского учета для кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}warehouse/`,
				},
				{
					property: "og:title",
					content: "Автоматизация складского учета для кафе и ресторанов | Quick Resto Казахстан",
				},
				{
					property: "og:description",
					content:
						"Программа складского учета для кафе и ресторанов ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}warehouse/`,
		};
	}

	if (locale === "ru-BY") {
		return {
			title: "Кухонный экран для повара Quick Resto Kitchen Display System | Беларусь",
			metaTags: [
				{
					name: "description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}warehouse/`,
				},
				{
					property: "og:title",
					content: "Кухонный экран для повара Quick Resto Kitchen Display System | Беларусь",
				},
				{
					property: "og:description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${PresentationBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}warehouse/`,
		};
	}

	return {
		title: "Программа учета для общепита — автоматизация складского учета кафе и ресторанов | Quick Resto",
		metaTags: [
			{
				name: "description",
				content:
					"Программа складского учета для кафе и ресторанов  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}warehouse/`,
			},
			{
				property: "og:title",
				content: "Программа автоматизации складского учета для кафе и ресторанов | Quick Resto",
			},
			{
				property: "og:description",
				content:
					"Программа складского учета для кафе и ресторанов  ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: `/${Presentation}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}warehouse/`,
	};
};
